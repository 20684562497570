import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Layout from "../components/layout";
import { IIFormInput } from "./disclaimer";
import { VerifiedProductItem } from "./index";
import { SelectFetch } from "react-select-fetch";
import Select from "react-select";
import {
  headerTitle,
  inputFormContainer,
  formTitleLabel,
  walnutPrimaryButton,
  externalComponentControlStyle
} from "../components/layout.module.css";
import { useForm, Controller, SubmitHandler, get } from "react-hook-form";
import { PageProps, navigate } from "gatsby";
import { ErrorMessage } from "@hookform/error-message";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export interface IIIFormInput {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  // state: SelectOption;
  store: string;
  dateOfPurchase: string;
}

interface SelectOption {
  value: string;
  label: string;
}

// const stateOptions: SelectOption[] = [
//   { value: "johor", label: "Johor" },
//   { value: "kedah", label: "Kedah" },
//   { value: "kelantan", label: "Kelantan" },
//   { value: "kuala-lumpur", label: "Kuala Lumpur" },
//   { value: "labuan", label: "Labuan" },
//   { value: "melaka", label: "Melaka" },
//   { value: "negeri-sembilan", label: "Negeri Sembilan" },
//   { value: "pahang", label: "Pahang" },
//   { value: "pulau-pinang", label: "Pulau Pinang" },
//   { value: "perak", label: "Perak" },
//   { value: "perlis", label: "Perlis" },
//   { value: "putrajaya", label: "Putrajaya" },
//   { value: "sabah", label: "Sabah" },
//   { value: "sarawak", label: "Sarawak" },
//   { value: "selangor", label: "Selangor" },
//   { value: "terengganu", label: "Terengganu" },
// ];

interface LocationState {
  productState: VerifiedProductItem;
  termState: IIFormInput;
}

const isBrowser = typeof window !== "undefined";

const InputFormPage = ({ location }: PageProps) => {
  const stateData = location.state as LocationState;

  if (stateData) {
    const [uniq, setUniq] = React.useState(0);
    const {
      control,
      handleSubmit,
      formState: { errors },
      getValues,
      resetField,
    } = useForm<IIIFormInput>();

    const onSubmit: SubmitHandler<IIIFormInput> = (data) => {
      navigate("/upload-form/", {
        state: {
          productState: stateData.productState,
          termState: stateData.termState,
          purchaseState: data,
        },
      });      
    };

    return (
      <Layout>
        <div className={inputFormContainer}>
          <h5 className={headerTitle}>Purchaser Detail</h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label className={formTitleLabel}>First Name</Form.Label>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: { value: true, message: "Input is required" },
                  pattern: {
                    value: /^[a-zA-Z]+(([\/',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    message: "Incorrect format",
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    size="lg"
                    {...field}
                    isInvalid={errors.firstName != null}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <Form.Control.Feedback type="invalid">
                    {message}
                  </Form.Control.Feedback>
                )}
              ></ErrorMessage>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label className={formTitleLabel}>Last Name</Form.Label>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: { value: true, message: "Input is required" },
                  pattern: {
                    value: /^[a-zA-Z]+(([\/',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    message: "Incorrect format",
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    size="lg"
                    {...field}
                    isInvalid={errors.lastName != null}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <Form.Control.Feedback type="invalid">
                    {message}
                  </Form.Control.Feedback>
                )}
              ></ErrorMessage>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMobile">
              <Form.Label className={formTitleLabel}>
                Mobile Phone Number
              </Form.Label>
              <Controller
                name="mobile"
                control={control}
                rules={{
                  required: { value: true, message: "Input is required" },
                  //Allow for Asean mobile phone number 
                  //pattern: {
                  //   value:
                  //     /^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/,
                  //   message: "Incorrect Malaysia phone number format",
                  // },
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country={'my'}
                    countryCodeEditable={false}
                    placeholder=""
                    onlyCountries={[
                      "my",
                      "sg",
                      "id",
                      "th",
                      "bn",
                      "ph",
                      "vn",
                      "la",
                      "kh",
                      "mm",
                    ]}
                    autoFormat={false}                                        
                    inputClass={externalComponentControlStyle}
                  ></PhoneInput>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="mobile"
                render={({ message }) => (
                  <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                    {message}
                  </Form.Control.Feedback>
                )}
              ></ErrorMessage>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label className={formTitleLabel}>Email address <span className="text-muted" style={{ fontSize: "0.75rem"}}>(Optional)</span></Form.Label>
              <Controller
                name="email"
                control={control}
                rules={{  
                  pattern: {
                    value: /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/,
                    message: "Incorrect Email format",
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    size="lg"
                    {...field}
                    isInvalid={errors.email != null}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <Form.Control.Feedback type="invalid">
                    {message}
                  </Form.Control.Feedback>
                )}
              ></ErrorMessage>
            </Form.Group>
            <h5 className={headerTitle}>Purchase Detail</h5>
            {/* <Form.Group className="mb-3" controlId="formState">
              <Form.Label className={formTitleLabel}>State</Form.Label>
              <Controller              
                name="state"
                control={control}
                rules={{
                  required: { value: true, message: "Input is required" },
                }}
                render={({ field }) => (
                  <Select                  
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(newValue) => {
                      setUniq(uniq + 1);
                      resetField("store", { defaultValue: null });
                      field.onChange(newValue);
                    }}
                    ref={field.ref}
                    value={field.value}
                    options={stateOptions}
                    className={externalComponentControlStyle}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="state"
                render={({ message }) => (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {message}
                  </Form.Control.Feedback>
                )}
              />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="formStoreName">
              <Form.Label className={formTitleLabel}>Store Name</Form.Label>

              <Controller
                name="store"
                control={control}
                rules={{
                  required: { value: true, message: "Input is required" },
                }}
                render={({ field }) => (
                  <SelectFetch
                    debounceTimeout={300}
                    loadOptionsOnMenuOpen={true}
                    url={`${process.env.API_URL}/store/list`}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    queryParams={{ limit: 10 }}
                    cacheUniqs={[uniq]}
                    mapResponse={(response) => ({
                      options: response.results,
                      hasMore: response.hasMore,
                    })}
                    className={externalComponentControlStyle}
                  ></SelectFetch>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="store"
                render={({ message }) => (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {message}
                  </Form.Control.Feedback>
                )}
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formDOP">
              <Form.Label className={formTitleLabel}>
                Date of Purchase
              </Form.Label>
              <Controller
                name="dateOfPurchase"
                control={control}
                rules={{
                  required: { value: true, message: "Input is required" },
                  pattern: {
                    value:
                      /^(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:(?:(\/|-|\.)(?:0?[13578]|1[02])\1(?:31))|(?:(\/|-|\.)(?:0?[13-9]|1[0-2])\2(?:29|30)))$| ^(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(\/|-|\.)0?2\3(?:29)$|^(?:(?:1[6-9]|[2-9]\d)?\d{2})(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:0?[1-9]|1\d|2[0-8])$/,
                    message: "Incorrect format",
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    type="date"
                    size="lg"
                    {...field}
                    isInvalid={errors.dateOfPurchase != null}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="dateOfPurchase"
                render={({ message }) => (
                  <Form.Control.Feedback type="invalid">
                    {message}
                  </Form.Control.Feedback>
                )}
              />
            </Form.Group>
            <Form.Text muted>
              You may refer to the purchase date on the receipt. Mismatch
              between input date and date on receipt void the warranty.
            </Form.Text>
            <div className="d-grid mt-5">
              <Button type="submit" size="lg" className={walnutPrimaryButton}>
                NEXT
              </Button>
            </div>
          </Form>
        </div>
      </Layout>
    );
  } else {
    if (isBrowser) {
      navigate("/", { replace: true });
    }
    return <Layout></Layout>;
  }
};

export default InputFormPage;
